.library-label {
    font-size: 48px;
    padding-bottom: 10px;
    color: #D58DC2;
}

.library-playlist-label {
    font-size: 48px;
    padding-bottom: 20px;
    color: #D58DC2;
}

.table-scroll {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 20px;
}

.card {
    background-color: #212529;
}

.lib-img {
    object-fit: fill;
    float: left;
    margin-right: 20px;
}

.lib-song-name {
    padding-top: 12px;
    font-weight: 600;
}

.lib-song-artist {
    font-weight: 100;
}

.td-element {
    border: none;
}

.date-added {
    color: #a9a9a9;
    font-weight: 100;
}

.text-gray {
    color: #a9a9a9;
}

.playlist {
    text-align: center;
    font-size: 18px;
    color: white;
}

.playlist-img {
    opacity: 1;
    transition: .5s ease;
}

.playlist-container:hover {
    opacity: 0.6;
}

.playlist-container {
    position: relative
}

.playlist-container:hover .play-img {
    display: block;
}

.play-img {
    position: absolute;
    display: none;
    top: 30%;
    margin: 0 auto;
    left: 0px;
    right: 0px;
}

.lib-img {
    opacity: 1;
    transition: .5s ease;
}



img {
    object-fit: cover;
}

/* tr:hover {
    color: #D58DC2;
} */

.current-song:hover {
    color: #D58DC2;
}

td .song-link {
    display: block;
    position: relative;
    z-index: 1;
    padding: 2em;
    margin: -2em;
    color: #a9a9a9;
    text-decoration: none;
}

td .song-link-artist {
    color: #a9a9a9;
    text-decoration: none;
}

.playlist-img {
    border-radius: 20px;
}

.song-link {
    text-decoration: none;
}

.link {
    text-decoration: none;
}