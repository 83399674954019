.page-info {
    font-size: 42px;
    font-weight: 500;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    color: white;
    background-color: black;

}

.song-rank {
    font-size: 48px;
    font-weight: bold;
    position: relative;
    top: 40%;
    margin-bottom: 25%;
    font-family: 'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
}

.song-name {
    font-size: 28px;
    font-weight: bold;
}

.container-sm.song {
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background-color: #212529;
    border-radius: 40px;
}

.container-sm.song:hover{
    background-color: #9F548B;
}

.album-img {
    transition: 0.5s ease;
}

.album-img:hover {
-webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 0.5s ease;
}

div {
    color: white;
}

.btn-pink {
    color: black;
    background-color: #D58DC2;
    font-weight: 600;
    margin-bottom: 20px;
}

.btn-pink:hover {
    color: white;
    background-color: #9F548B;
}


.btn-block {
    width: 100%
}

.time-frame {
    background-color: black !important;
}

.button-row {
    flex:grid;
}
