.song-title {
    font-size: 48px;
    font-weight: 900;
    margin-top: 10%;
}

.song-artists {
    font-size: 32px;
    font-weight: 700;
    color:grey;
}

.album-img-info {
    border-radius: 7px;
    margin-top: 20%;
}

.info-label {
    color: grey;
    margin-top: 10%;
    font-size: 20px;
    font-weight: 500;
}

.album-info {
    font-size: 32px;
    font-weight: 500;
}

.audio-player {
    margin-top: 20px;
}

.song-details {
    padding-left: 5%;
}

.not-available {
    text-align: center;
    font-size: 30px;
    margin-top: 5%;
    margin-bottom: 10%;
}

.adjust {
    padding-left: 1.5rem;
}

.progress-bar {
    background-color: #D58DC2 !important;
}

.song-page-label {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 36px;
    font-weight: 600;
    color:#D58DC2;

}

.analysis-label {
    color: white;
    margin-top: 5%;
    font-size: 20px;
}

.progress {
    height: 10px;
    margin-top: 10px;
    width: 95%
}

.analysis {
    color: white;
    padding-top: 10px;
    padding-left: 8%;
    padding-bottom: 20px;
    margin-top: 5%;
    margin-bottom: 20px;
    background-color: #212529;
    border-radius: 40px;
}

.song-key-info {
    padding-top: 4.5%;
    text-align: center;
}

.genres {
    font-size: 22px;
}

.song-info-label {
    font-size: 30px;
    font-weight: 750;
    float: left;
}

.info-label-name {
    font-size: 30px;
    color: grey;
    font-weight: 600;
    float: left;
    padding-right: 32px;
    padding-left: 12px;
}

.recom-label {
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: 36px;
    font-weight: 700;
    color: #D58DC2;
}

