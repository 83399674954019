.container-sm.artist {
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background-color: #212529;
    border-radius: 40px;
}

.artist-img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 0.5s ease;
}