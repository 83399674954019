.navbar {
    text-align: center;
}

.navbar-brand {
    margin-left: 20px;
}

.navbar-nav {
    text-align: center;
}
.nav-link {
    font-size: 22px;
    margin-left: 32px;
    transition: all 200ms;
}
.header > .nav-link:hover {
    font-size: 40px;
}

.navbar-right {
    margin-left: 200px;
}

.search-pink {
    background-color: #D58DC2;
}

@media (max-width : 800px) {
    .nav-link {
        padding-bottom: 15px;
    }
    .d-flex {
            margin-left: auto;
            margin-right: auto;
        }

}



